import { useState, ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"
import { User, postSignup } from "../api/client"

interface SignupProps {
    onSubmitSuccess: (user: User) => void
}

function Signup({ onSubmitSuccess }: SignupProps) {
  const [name, setName] = useState("")
  const [emoji, setEmoji] = useState("")
  const [phone, setPhone] = useState("")
  const [isValid, setIsValid] = useState<boolean>(true);
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    try {
      const user = await postSignup(name, emoji, phone)
      console.log("after postsignup: user", user)
      if (!user) {
        throw new Error("Login failed")
      }

      onSubmitSuccess(user)
      navigate("/rules")
    } catch (err) {
      setError(err instanceof Error ? err.message : "Submitting info failed")
    } finally {
      setIsLoading(false)
    }
  }

  const formatPhoneNumber = (input: string): string => {
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, '');
    
    // Format as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    
    if (!match) return '';
    
    const parts = [match[1], match[2], match[3]].filter(Boolean);
    
    if (parts.length === 0) return '';
    if (parts.length === 1) return `(${parts[0]}`;
    if (parts.length === 2) return `(${parts[0]}) ${parts[1]}`;
    return `(${parts[0]}) ${parts[1]}-${parts[2]}`;
  };

  const validatePhoneNumber = (phone: string): boolean => {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly.length === 10;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatPhoneNumber(inputValue);
    
    setPhone(formattedValue);
    setIsValid(validatePhoneNumber(formattedValue));
  };

  const isSingleEmoji = (str: string): boolean => {
    // This regex matches:
    // - Single emoji with optional skin tone modifier
    // - Emoji sequences (like combined emojis)
    // - ZWJ sequences (zero-width joiner combinations)
    const emojiRegex = /^(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F)(?:\p{Emoji_Modifier})?(?:\u200D(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F)(?:\p{Emoji_Modifier})?)*$/u
    return emojiRegex.test(str)
  }

  return (
    <div className="min-vh-100 bg-light d-flex flex-column justify-content-center py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4">
        <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Who are you?</h5>
            </div>
              <div className="card-body p-4">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label 
                      htmlFor="name" 
                      className="form-label"
                    >
                      Factory Visitor Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      value={name}
                      placeholder="E.g. Jane Smith, Ben Trashin"
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                    />
                  </div>

                  <div className="mb-3">
                    <label 
                      htmlFor="emoji" 
                      className="form-label"
                    >
                    Favorite Emoji
                    </label>
                    <input
                      id="emoji"
                      name="emoji"
                      type="text"
                      required
                      value={emoji}
                      placeholder="E.g. 😵‍💫"
                      onChange={(e) => {
                        const input = e.target.value
                        // Only update if input is empty or a single emoji
                        if (input === '' || isSingleEmoji(input)) {
                          setEmoji(input)
                        }
                      }}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group mb-4">
                    <label 
                      htmlFor="phone" 
                      className="form-label"
                    >
                    Phone number
                    </label>

                    <input
                      type="tel"
                      className={`form-control ${!isValid && phone ? 'is-invalid' : ''}`}
                      value={phone}
                      onChange={handleChange}
                      placeholder="111-222-33333"
                      required={true}
                      // (XXX) XXX-XXXX format has 14 characters
                      maxLength={14}
                      aria-label="Phone number"
                    />
                    {!isValid && phone && (
                      <div className="invalid-feedback">
                        Please enter a valid 10-digit phone number
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-primary w-100"
                  >
                    {isLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div 
                          className="spinner-border spinner-border-sm me-2" 
                          role="status"
                          aria-hidden="true"
                        />
                        Accepting your trash...
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup