// Color Square Component
interface ColorSquareProps {
  color: string;
}

export const ColorSquare: React.FC<ColorSquareProps> = ({ color }) => (
  <div 
    className="d-inline-block me-2"
    style={{ backgroundColor: color, border: color === 'white' ? '1px solid #ccc' : 'none', width: '1rem', height: '1rem' }}
  />
);

export const ColorCircle: React.FC<ColorSquareProps> = ({ color }) => (
  <div 
    className="d-inline-block me-2 rounded-circle"
    style={{ backgroundColor: color, border: color === 'white' ? '1px solid #ccc' : 'none', width: '1rem', height: '1rem' }}
  />
);