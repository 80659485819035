import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { AxiosError } from "axios"
import { postGemDiscovery } from "../api/client"

interface LocateFormProps {
  onBack: () => void
}
const LocateForm: React.FC<LocateFormProps> = ({ onBack }) => {
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [code, setCode] = useState("")
  
    const navigate = useNavigate()
  
    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
      if (value.length <= 4) {
        setCode(value)
      }
    }
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      setError("")
      setIsLoading(true)
  
      try {
        if (code) {
          await postGemDiscovery(code)
          navigate("/")
        } else {
          throw new Error("Code is required")
        }
      } catch (err: any) {
        const message = err instanceof AxiosError ? err.response?.data?.detail : (err as Error).message
        console.error("Gem discovery failed:", message)
        setError(message || "Failed to submit discovery")
      } finally {
        setIsLoading(false)
      }
    }
  
    return (
      <div className="card">
        <div className="card-body">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="h5 mb-0">Discover Hidden Trash</h3>
            <button className="btn btn-sm btn-outline-secondary" onClick={onBack}>
              <i className="bi bi-arrow-left"></i> Back
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Code</label>
              <input
                id="code"
                name="code"
                type="text"
                className="form-control form-control-lg text-center"
                value={code}
                onChange={handleCodeChange}
                placeholder="XXXX"
                maxLength={4}
                autoComplete="off"
                required
              />
              <div className="form-text">
                Did you find something?
              </div>
            </div>
            <button 
              type="submit" 
              className="btn btn-warning"
              disabled={code.length !== 4}
            >
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  />
                  Submitting...
                </div>
              ) : (
                <>
                  <MagnifyingGlassIcon style={{ width: '1.25rem', height: '1.25rem' }} className="me-2" />
                  Submit Discovery
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    )
  }
  
  export default LocateForm