import { 
  TrophyIcon
} from "@heroicons/react/24/outline"
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { postTossing } from "../api/client"
import { AxiosError } from "axios"
import { InstructionsCard } from './InstructionsCard'

const tossingInstructions = [
  "Report to Room 1712",
  "Find another visitor who wants to toss trash",
  "Stand 10 feet from the trash bins",
  "On staff signal, toss as many pieces of trash into your bin in < 2 minutes",
  "Count and record the tallies",
]

interface TossingFormProps {
  userPublicKey: string
  onBack: () => void
}

const ReportTossing: React.FC<TossingFormProps> = ({ userPublicKey, onBack }) => {
  const [showInstructions, setShowInstructions] = useState(true)
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [opponentKey, setOpponentKey] = useState("")
  const [playerScore, setPlayerScore] = useState("")
  const [opponentScore, setOpponentScore] = useState("")
  
  const navigate = useNavigate()

  if (showInstructions) {
    return (
      <InstructionsCard
        title="Tossin' Trash"
        instructions={tossingInstructions}
        onBack={onBack}
        onContinue={() => setShowInstructions(false)}
      />
    )
  }

// Prevent non-digit input and enforce reasonable limits
  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>, setScore: (value: string) => void) => {
    const value = e.target.value
    // Only allow digits and empty string
    if (value === '' || /^\d+$/.test(value)) {
      // Limit to reasonable score (e.g., 0-999)
      if (value === '' || parseInt(value) <= 999) {
        setScore(value)
      }
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    try {
      if (!opponentKey || !playerScore || !opponentScore) {
        throw new Error("All fields are required")
      }

      if (opponentKey === userPublicKey) {
        throw new Error("You cannot play against yourself")
      }

      const score1 = parseInt(playerScore)
      const score2 = parseInt(opponentScore)
      
      if (isNaN(score1) || isNaN(score2) || score1 < 0 || score2 < 0) {
        throw new Error("Scores must be valid positive numbers")
      }

      await postTossing({
        opponent_key: opponentKey,
        player_score: score1,
        opponent_score: score2
      })
      navigate("/")
    } catch (err: any) {
      const message = err instanceof AxiosError ? err.response?.data?.detail : (err as Error).message
      console.error("Tossing submission failed:", message)
      setError(message || "Submission failed :(")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="card">
      <div className="card-body">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="h5 mb-0">Report Trash Tossing Match</h3>
          <button className="btn btn-sm btn-outline-secondary" onClick={onBack}>
            <i className="bi bi-arrow-left"></i> Back
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <h6>Your Details</h6>
            <div className="input-group mb-2">
              <label className="input-group-text">Your ID</label>
              <input
                type="text"
                className="form-control bg-light"
                value={userPublicKey}
                disabled
              />
            </div>
            <div className="input-group">
              <label className="input-group-text">Your Score</label>
              <input
                type="text" 
                inputMode="numeric"
                pattern="\d*"
                className="form-control"
                value={playerScore}
                onChange={(e) => handleScoreChange(e, setPlayerScore)}
                placeholder="Number of baskets"
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <h6>Opponent Details</h6>
            <div className="input-group mb-2">
              <label className="input-group-text">Opponent ID</label>
              <input
                type="text"
                className="form-control"
                value={opponentKey}
                pattern="^[a-z]{2}$"
                maxLength={2}
                onChange={(e) => setOpponentKey(e.target.value.toLowerCase())}
                placeholder="e.g. xy"
                required
              />
            </div>
            <div className="input-group">
              <label className="input-group-text">Opponent Score</label>
              <input
                type="text"
                inputMode="numeric"
                pattern="\d*"
                className="form-control"
                value={opponentScore}
                onChange={(e) => handleScoreChange(e, setOpponentScore)}
                placeholder="Number of baskets"
                required
              />
            </div>
          </div>

          <div>
            <button type="submit" className="btn btn-success">
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  />
                  Submitting...
                </div>
              ) : (
                <>
                  <TrophyIcon style={{ width: '1.25rem', height: '1.25rem' }} className="me-2" />
                  Submit Match
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReportTossing