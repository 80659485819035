import React from 'react';
import { TeamInfos } from './Responses';
import { ColorSquare, ColorCircle } from './Badges';
import { Roster, PublicUserView } from './Roster';
import { EncounteredUser, User } from '../api/client';
import EventFeed, { Event } from './Events';

export interface Steal {
  id: number
    robber: EncounteredUser
  victim: EncounteredUser
  occurred_at: number
  location: string
  contents: Record<number, number>
}

export interface AnonymizedBackpack {
  team_id: number;
  backpack: {
    [key: string]: number;
  };
}

export interface GodViewBackpack {
  user: User
  backpack: Record<number, number>
}

export interface TeamTallies {
  [team_id: string]: {
    [key: string]: number;
  };
}

export interface Stats {
  steals: Steal[];
  backpacks: AnonymizedBackpack[];
  team_tallies: TeamTallies;
  team_infos: TeamInfos;
}

// Utility function to format timestamp
const formatTime = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)
  const weekday = date.toLocaleString('en-US', { weekday: 'short' })
  return `${weekday} ${date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
}


// Trash Counter Component
interface TrashCounterProps {
  counts: Record<string, number>;
  teams: TeamInfos;
}

interface PlayerRankInfo {
  rank: number
  total_players: number
  nearby_scores: {
    ahead: number | null
    behind: number | null
  }
}

interface TrashCounterProps {
  counts: Record<string, number>
  teams: TeamInfos
  rank?: any
}

const TrashCounter: React.FC<TrashCounterProps> = ({ counts, teams, rank }) => {
  const total = Object.values(counts).reduce((sum, count) => sum + count, 0)
  
  if (total === 0) {
    return <span className="text-muted">Your backpack contains nothing</span>
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h6 className="mb-3">Your Collection</h6>
          {Object.entries(counts).map(([teamId, count]) => count > 0 && (
            <div key={teamId} className="d-flex align-items-center mb-2">
              <ColorCircle color={teams[teamId]?.color_code} />
              <span className="ms-2">{count} pieces</span>
            </div>
          ))}
        </div>
        {rank && (
          <div className="col-md-6">
            <h6 className="mb-3">Your Rank</h6>
            <div className="card border-0">
              <div className="card-body">
                <div className="text-center mb-3">
                  <h3 className="mb-0">{rank.rank}</h3>
                  <small className="text-muted">of {rank.total_players} players</small>
                </div>
                <div className="nearby-scores">
                  {rank.rank > 1 && rank.score_ahead !== null && (
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <small className="text-muted">Player ahead</small>
                      <span className="badge bg-secondary">
                        <i className="hero-arrow-up me-1"></i>
                        {rank.score_ahead} pieces
                      </span>
                    </div>
                  )}
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <small className="text-muted">You</small>
                      <span className="badge bg-secondary">
                        <i className="hero-arrow-down me-1"></i>
                        {total} pieces
                      </span>
                    </div>

                  {rank.rank < rank.total_players && rank.score_behind !== null && (
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <small className="text-muted">Player behind</small>
                      <span className="badge bg-secondary">
                        <i className="hero-arrow-down me-1"></i>
                        {rank.score_behind} pieces
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

// Steal Entry Component
interface StealEntryProps {
  steal: Steal;
  teams: TeamInfos;
  publicView?: boolean;
  currentPlayerKey?: string;
}

const StealEntry: React.FC<StealEntryProps> = ({ 
  steal, 
  teams, 
  publicView = true, 
  currentPlayerKey
}) => {
  const robberColor = steal.robber.team.color_code
  const victimColor = steal.victim.team.color_code
	const robberId = publicView ? steal.robber.team.name : steal.robber.public_key
	const victimId = publicView ? steal.victim.team.name : steal.victim.public_key
	const contentsSum = Object.values(steal.contents).reduce((sum, count) => sum + count, 0);
  return (
			<div className="border rounded p-2" style={{ minWidth: '100px', flex: '1 0 auto', maxWidth: '200px' }}>
        {!publicView && currentPlayerKey && (
          <span className="fw-bold me-2">
            {(steal.robber.public_key === currentPlayerKey ? "Scored" : "Lost") + " " + contentsSum + " trash"}
          </span>
        )}
				<div className="d-flex align-items-center gap-2 small">
        <ColorSquare color={robberColor} />
				{!publicView && <span className="me-2">{robberId}</span>}
        <span className="mx-2">←</span>
        <ColorSquare color={victimColor} />
				{!publicView && <span className="me-2">{victimId}</span>}
      </div>
			<div className="d-flex gap-2 flex-wrap flex-grow-1">
				{Object.entries(steal.contents).map(([colorId, count]) => count > 0 && (
					<div key={colorId} className="d-flex align-items-center">
						<ColorCircle color={teams[colorId]?.color_code} />
						<span>{count}</span>
					</div>
				))}
			</div>
      <div className="small text-muted">
        <div>{formatTime(steal.occurred_at)}</div>
        {steal.location && (
          <div 
            title={steal.location}
            style={{
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              maxWidth: '180px',
              wordBreak: 'break-word'
            }}
          >
            📍 {steal.location}
          </div>
        )}
      </div>
    </div>
  );
};

// Steals List Component
interface StealsListProps {
  steals: Steal[];
  teams: TeamInfos;
  showTeams?: boolean;
  isBackpackView?: boolean;
  currentPlayerKey?: string;
}

const PublicStealsList: React.FC<StealsListProps> = ({ steals, teams, currentPlayerKey }) => (
  <div className="card">
    <div className="card-header">
      <h5 className="card-title mb-0">
				Recent Steals
			</h5>
    </div>
    <div className="card-body">
	  	<div className="d-flex flex-wrap gap-2">
				{steals.sort((a, b) => b.occurred_at - a.occurred_at).map(steal => (
					<StealEntry 
						key={steal.id} 
						steal={steal} 
						teams={teams}
						publicView={true}
						currentPlayerKey={currentPlayerKey}
					/>
				))}
    	</div>
    </div>
  </div>
);

const PrivateStealsList: React.FC<StealsListProps> = ({ steals, teams, currentPlayerKey }) => (
  <div className="card">
    <div className="card-header">
      <h5 className="card-title mb-0">
			Your Encounters
			</h5>
    </div>
    <div className="card-body">
	  	<div className="d-flex flex-wrap gap-2">
				{steals.length === 0 && (
					<span className="text-muted">You haven't interacted with other visitors yet.</span>
				)}
				{steals.sort((a, b) => b.occurred_at - a.occurred_at).map(steal => (
					<StealEntry 
						key={steal.id} 
						steal={steal} 
						teams={teams}
						publicView={false}
						currentPlayerKey={currentPlayerKey}
					/>
				))}
    	</div>
    </div>
  </div>
);

// Team Standings Component
interface TeamStandingsProps {
  teamTallies: Record<string, TeamTally>
  teams: TeamInfos
  endTime: number
}

const TeamStandings: React.FC<TeamStandingsProps> = ({ teamTallies, teams, endTime }) => {
  const sortedTeams = Object.entries(teamTallies)
    .map(([teamId, counts]) => ({
      teamId,
      team: teams[teamId],
      counts,
      total: Object.values(counts).reduce((sum, count) => sum + count, 0)
    }))
    .sort((a, b) => b.total - a.total)

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="card-title mb-0">Faction Tallies</h5>
        <CountdownTimer endTime={endTime} />
      </div>
      <div className="card-body">
        {sortedTeams.map(({ team, counts, total }) => (
          <div key={team.id} className="border-bottom p-3">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center">
                <ColorSquare color={team.color_code} />
                <span className="fw-bold ms-2">{team.name}</span>
              </div>
              
              <div className="d-flex align-items-center gap-4 ms-auto">
                <div className="d-flex gap-4">
                  {Object.entries(counts).map(([colorId, count]) => count > 0 && (
                    <div key={colorId} className="d-flex align-items-center">
                      <ColorCircle color={teams[colorId]?.color_code} />
                      <span className="ms-1">{count}</span>
                    </div>
                  ))}
                </div>
                <div className="fw-bold" style={{ minWidth: '100px' }}>
                  Total: {total}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

// Individual Standings Component
interface IndividualStandingsProps {
  backpacks: AnonymizedBackpack[];
  teams: TeamInfos;
}

const IndividualStandings: React.FC<IndividualStandingsProps> = ({ backpacks, teams }) => {
  const playerTallies = backpacks.slice(0, 20).reduce((acc, { team_id, backpack }) => {
    const total = Object.values(backpack).reduce((sum, count) => sum + count, 0);
    acc.push({ team_id, backpack, total });
    return acc;
  }, [] as Array<{ team_id: number; backpack: Record<number, number>; total: number }>)
    .sort((a, b) => b.total - a.total);

    return (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Heavy Backpacks</h5>
          </div>
          <div className="card-body">
            <div className="d-flex flex-wrap gap-2">
              {playerTallies.map((player, index) => (
                <div key={index} className="border rounded p-2" style={{ minWidth: '200px', flex: '1 0 auto', maxWidth: '300px' }}>
                  <div className="d-flex align-items-center gap-2 small">
                		<span>{index + 1}.</span>
                    <ColorSquare color={teams[player.team_id]?.color_code} />
                    <div className="ms-auto fw-bold">{player.total}</div>
                    <div className="d-flex gap-2 flex-wrap flex-grow-1">
                      {Object.entries(player.backpack).map(([colorId, count]) => count > 0 && (
                        <div key={colorId} className="d-flex align-items-center">
                          <ColorCircle color={teams[colorId]?.color_code} />
                          <span>{count}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
};

interface GodViewIndividualStandingsProps {
  backpacks: GodViewBackpack[];
  teams: TeamInfos;
}

const GodViewIndividualStandings: React.FC<IndividualStandingsProps> = ({ backpacks, teams }) => {
  const playerTallies = backpacks.slice(0, 20).reduce((acc, { user, team_id, backpack }) => {
    const total = Object.values(backpack).reduce((sum, count) => sum + count, 0);
    acc.push({ user, team_id, backpack, total });
    return acc;
  }, [] as Array<{ user: EncounteredUser, team_id: number; backpack: Record<number, number>; total: number }>)
    .sort((a, b) => b.total - a.total);

    console.log("god view", playerTallies)
    return (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Heavy Backpacks</h5>
          </div>
          <div className="card-body">
            <div className="d-flex flex-wrap gap-2">
              {playerTallies.map((player, index) => (
                <div key={index} className="border rounded p-2" style={{ minWidth: '200px', flex: '1 0 auto', maxWidth: '300px' }}>
                  <div className="d-flex align-items-center gap-2 small">
                		<span>{index + 1}.</span>
                    <ColorSquare color={teams[player.team_id]?.color_code} />
                    <span>{player.user.public_key} - {player.user.name}</span>
                    <div className="ms-auto fw-bold">{player.total}</div>
                    <div className="d-flex gap-2 flex-wrap flex-grow-1">
                      {Object.entries(player.backpack).map(([colorId, count]) => count > 0 && (
                        <div key={colorId} className="d-flex align-items-center">
                          <ColorCircle color={teams[colorId]?.color_code} />
                          <span>{count}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
};



// Countdown Timer Component
interface CountdownTimerProps {
  endTime: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ endTime }) => {
  const [timeLeft, setTimeLeft] = React.useState('')
  const [finalStretch, setFinalStretch] = React.useState(false)

  React.useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime()
      const distance = endTime * 1000 - now
      if (distance < 0) {
        clearInterval(timer)
        setTimeLeft("GAME OVER")
        return
      }
      const hours = Math.floor(distance / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      if (hours > 0) {
        setTimeLeft(`${hours}:${minutes.toString().padStart(2, '0')} Hours Left`)
        return
      }
      setFinalStretch(true)
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      setTimeLeft(`${minutes}:${seconds.toString().padStart(2, '0')} Mins Left`)
    }
    const timer = setInterval(updateTimer, 1000)
    updateTimer()
    return () => clearInterval(timer)
  }, [endTime])

  return (
    <div className={`d-flex align-items-center gap-2 ${finalStretch ? 'text-danger' : ''}`}>
      <span className="">
        {timeLeft}
      </span>
    </div>
  )
}


// Backpack Page Component
interface BackpackPageProps {
  viewerID: number
  backpack: Record<number, number>
  events: Event[]
  teams: TeamInfos
  endTime: number
}

const BackpackPage: React.FC<BackpackPageProps> = ({ viewerID, backpack, events, teams, endTime, rank }) => {
  return (
    <div className="container-fluid py-4">
      <div className="row g-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Backpack</h5>
              <CountdownTimer endTime={endTime} />
            </div>
            <div className="card-body">
              <TrashCounter counts={backpack} teams={teams} rank={rank} />
            </div>
          </div>
        </div>
        <div className="col-12">
          <EventFeed viewerID={viewerID} events={events} teams={teams}/>
        </div>
      </div>
    </div>
  )
}

// Global Stats Page Component
interface GlobalStatsPageProps {
  visitors: PublicUserView[]
  teamTallies: Record<string, TeamTally>
  backpacks: Backpack[]
  steals: Steal[]
  teams: TeamInfos
  events: Event[]
  endTime: number
}

const GlobalStatsPage: React.FC<GlobalStatsPageProps> = ({ visitors, teamTallies, backpacks, teams, events, endTime }) => {
  return (
    <div className="container-fluid py-4">
      <div className="row g-4">
        <div className={"alert col-12 col-md-10 col-lg-8 mb-0 container alert-info"}>
          <div className="row justify-content-center text-center">
            Social hour, snacks, in-person missions Sat 9-10pm at Room 1727. Stealing and diggin' throughout the factory any time. Awards ceremony Mon 1am in 1727.
          </div>
        </div>

        <div className="col-12">
          <TeamStandings teamTallies={teamTallies} teams={teams} endTime={endTime} />
        </div>
        <div className="col-12">
          <IndividualStandings backpacks={backpacks} teams={teams} />
        </div>
        <div className="col-12">
          <Roster visitors={visitors} teams={teams} />
        </div>
        <div className="col-12">
          <EventFeed viewerID={null} events={events} teams={teams} anonymized={true}/>
        </div>
      </div>
    </div>
)
}

const GodGlobalStatsPage: React.FC<GlobalStatsPageProps> = ({ visitors, teamTallies, backpacks, teams, events }) => {
  return (
    <div className="container-fluid py-4">
      <div className="row g-4">
        <div className="col-12">
          <TeamStandings teamTallies={teamTallies} teams={teams} />
        </div>
        <div className="col-12">
          <GodViewIndividualStandings backpacks={backpacks} teams={teams} />
        </div>
        <div className="col-12">
          <Roster visitors={visitors} teams={teams} />
        </div>
        <div className="col-12">
          <EventFeed viewerID={null} events={events} teams={teams} anonymized={false}/>
        </div>
      </div>
    </div>
)
}


export { BackpackPage, GlobalStatsPage, GodGlobalStatsPage };