import React, { useState, useEffect } from 'react'
import { EncounteredUser, postSurveyResponses } from '../api/client'
import { ColorSquare } from './Badges'

interface SurveyData {
  prompts: Prompt[]
  categories: PromptCategory[]
  reward: number
  has_user_taken_survey: boolean
  buddies: EncounteredUser[]
}

interface SurveyProps {
  surveyData: SurveyData
  onBack: (completed: boolean) => void
}

export interface Prompt {
  id: number
  text: string
  category_id: number
  placeholder: string
  valid_responses: string[] | null
}

export interface PromptCategory {
  id: number
  caption: string
  responses_required: number
}

const Survey: React.FC<SurveyProps> = ({ surveyData, onBack}) => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0)
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0)
  const [currentAnswer, setCurrentAnswer] = useState('')
  const [answers, setAnswers] = useState<Record<number, string>>({})
  const [answeredPromptIds, setAnsweredPromptIds] = useState<Set<number>>(new Set())
  const [randomizedPrompts, setRandomizedPrompts] = useState<Record<number, Prompt[]>>({})

  const categories = surveyData.categories
  const prompts = surveyData.prompts

  // Initialize randomized prompts on mount
  useEffect(() => {
    const shuffleArray = <T,>(array: T[]): T[] => {
      const newArray = [...array]
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
      }
      return newArray
    }

    // Group and shuffle prompts by category
    const grouped = prompts.reduce((acc, prompt) => {
      if (!acc[prompt.category_id]) {
        acc[prompt.category_id] = []
      }
      acc[prompt.category_id].push(prompt)
      return acc
    }, {} as Record<number, Prompt[]>)

    // Shuffle each category's prompts
    const shuffled = Object.fromEntries(
      Object.entries(grouped).map(([categoryId, categoryPrompts]) => [
        categoryId,
        shuffleArray(categoryPrompts)
      ])
    )

    setRandomizedPrompts(shuffled)
  }, [prompts])

  // Get current category and its prompts
  const currentCategory = categories[currentCategoryIndex]
  const currentCategoryPrompts = randomizedPrompts[currentCategory?.id] || []
  const currentPrompt = currentCategoryPrompts[currentPromptIndex]

  // Get count of answered prompts in current category
  const answeredInCategory = Array.from(answeredPromptIds).filter(
    id => prompts.find(p => p.id === id)?.category_id === currentCategory?.id
  ).length

  const getNextUnansweredPrompt = () => {
    let nextIndex = (currentPromptIndex + 1) % currentCategoryPrompts.length
    let loopCount = 0
    
    while (loopCount < currentCategoryPrompts.length) {
      if (!answeredPromptIds.has(currentCategoryPrompts[nextIndex].id)) {
        return nextIndex
      }
      nextIndex = (nextIndex + 1) % currentCategoryPrompts.length
      loopCount++
    }
    
    return -1
  }

  const handleAnswerSubmit = async (answer: string) => {
    setAnswers(prev => ({...prev, [currentPrompt.id]: answer.trim()}))
    setAnsweredPromptIds(prev => new Set([...prev, currentPrompt.id]))
    setCurrentAnswer('')

    if (answeredInCategory + 1 >= currentCategory.responses_required) {
      if (currentCategoryIndex < categories.length - 1) {
        setCurrentCategoryIndex(prev => prev + 1)
        setCurrentPromptIndex(0)
        return
      }
      await postSurveyResponses(answers)
      return
    }

    const nextIndex = getNextUnansweredPrompt()
    setCurrentPromptIndex(nextIndex)
  }

  const handleNext = () => {
    if (currentAnswer.trim()) {
      handleAnswerSubmit(currentAnswer)
    }
  }

  const handleMultipleChoiceSelect = (choice: string) => {
    handleAnswerSubmit(choice)
  }

  const handleSkip = () => {
    const nextIndex = getNextUnansweredPrompt()
    setCurrentPromptIndex(nextIndex)
    setCurrentAnswer('')
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && currentAnswer.trim()) {
      e.preventDefault()
      handleNext()
    }
  }

  const isComplete = currentCategoryIndex === categories.length - 1 && 
                    answeredInCategory >= currentCategory.responses_required

  if (!currentPrompt) return null

  const renderAnswerInput = () => {
    if (currentPrompt.valid_responses) {
      return (
        <div className="d-grid gap-2">
          {shuffleArray(currentPrompt.valid_responses).map((choice, index) => (
            <button
              key={index}
              className="btn btn-outline-primary text-start"
              onClick={() => handleMultipleChoiceSelect(choice)}
            >
              {choice}
            </button>
          ))}
        </div>
      )
    }

    return (
      <div className="d-flex gap-2 mb-3">
        <input
          type="text"
          className="form-control"
          value={currentAnswer}
          onChange={(e) => setCurrentAnswer(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={currentPrompt.placeholder}
          autoFocus
        />
        <button
          onClick={handleNext}
          disabled={!currentAnswer.trim()}
          className="btn btn-primary d-flex align-items-center"
          style={{ minWidth: '100px' }}
        >
          Next →
        </button>
      </div>
    )
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="h5 mb-0">{
            isComplete
              ? "You have earned trash! 🎉"
              : categories[currentCategoryIndex].caption
          }</h3>
          <button className="btn btn-sm btn-outline-secondary" onClick={() => onBack(isComplete)}>
            <i className="bi bi-arrow-left"></i> Back
          </button>
        </div>
        <div className="mb-4">
          {!isComplete ? (
            <>
              <h6 className="mb-4">
                {currentPrompt.text}
              </h6>
              
              {renderAnswerInput()}

              <div className="d-flex justify-content-between align-items-center mt-3">
                {currentCategoryPrompts.length > 1 && (
                <button
                  onClick={handleSkip}
                  className="btn btn-link text-decoration-none p-0"
                >
                  ↻ Change prompt
                </button>)}
                <small className="text-muted">
                  Answered {answeredInCategory} of {currentCategory.responses_required} required
                </small>
              </div>
            </>
          ) : (
            <>
              <div className="mb-4 text-center">
                <i>You just unlocked more friendship. Close this to check for buddies.</i>
              </div>
              {Array.from(answeredPromptIds).map(promptId => {
                const prompt = prompts.find(p => p.id === promptId)
                return (
                  <div key={promptId} className="p-3 bg-light rounded mb-3">
                    <div className="fw-bold">{prompt?.text}</div>
                    <div className="text-muted">{answers[promptId]}</div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

// Helper function to shuffle arrays
const shuffleArray = <T,>(array: T[]): T[] => {
  const newArray = [...array]
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
  }
  return newArray
}

interface BuddiesProps {
  survey: SurveyData
  onBack: () => void
}

export const Buddies: React.FC<BuddiesProps> = ({ survey, onBack}) => {
  console.log("buddies is here", survey.buddies)
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="h5 mb-0 text-info">Your Buddies</h3>
          <button className="btn btn-sm btn-outline-secondary" onClick={onBack}>
            <i className="bi bi-arrow-left"></i> Back
          </button>
        </div>
        <div className="mb-4">
          <i>
          Trash collection is more fun with friends! <br/><br/> When you complete a given task with a buddy, 
          you both earn each other's trash rewards as a bonus. Example: If you earn 1 trash for a given task,
          with a buddy, you get 2. With 2 buddies, you get 3, and so on.<br/><br/>
          Note: Buddies are specific assignments and may cross teams.</i>
        </div>
        {survey.buddies.map(buddy => (
          <div className="mb-4" key={buddy.id}>
          <h5 className="card-title mb-4">
            <ColorSquare color={buddy.team.color_code} /> {buddy.public_key} - {buddy.emoji} {buddy.name} 📱 {buddy.phone}
          </h5>
          {Object.values(buddy.responses).map((response) => {
              const prompt = survey.prompts.find(p => p.id === response.prompt_id)
              return (
                <div key={response.prompt_id} className="p-3 bg-light rounded mb-3">
                  <div className="fw-bold">{prompt?.text}</div>
                  <div className="text-muted">{response.answer}</div>
                </div>
              )
          })}
          </div>
        ))}
        {survey.buddies.length === 0 && (<div>
          We're still looking for a buddy for you. To get a buddy assignment,&nbsp;
          <b>help us get more visitors to fill out their surveys!</b></div>)}
    </div>
    </div>)
}

export { Survey }