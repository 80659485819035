// InstructionsCard.tsx
import React from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"

interface InstructionsCardProps {
  title: string
  instructions: string[]
  onBack: () => void
  onContinue: () => void
}

export const InstructionsCard: React.FC<InstructionsCardProps> = ({ 
  title, 
  instructions, 
  onBack, 
  onContinue 
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="h5 mb-0">{title}</h3>
          <button className="btn btn-sm btn-outline-secondary" onClick={onBack}>
            <ArrowLeftIcon style={{ width: '1rem', height: '1rem' }} className="me-1" />
            Back
          </button>
        </div>
        
        <div className="mb-4">
          {instructions.map((instruction, index) => (
            <div key={index} className="d-flex align-items-start mb-3">
              <span className="badge bg-secondary rounded-pill me-2 mt-1">
                {index + 1}
              </span>
              <p className="mb-0">{instruction}</p>
            </div>
          ))}
        </div>

        <button className="btn btn-primary" onClick={onContinue}>
          Let's Go!
          <ArrowRightIcon style={{ width: '1rem', height: '1rem' }} className="ms-2" />
        </button>
      </div>
    </div>
  )
}