import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { User, postLogin } from "../api/client"

interface LoginProps {
    onLoginSuccess: (user: User) => void
}

function Login({ onLoginSuccess }: LoginProps) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    try {
      const user = await postLogin(username, password)
      console.log("after postlogin: user", user)
      if (!user) {
        throw new Error("Login failed")
      }


      onLoginSuccess(user)
      if (user.name === "") {
        navigate("/signup")
        return
      }
      navigate("/rules")
    } catch (err) {
      setError(err instanceof Error ? err.message : "Login failed")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="min-vh-100 bg-light d-flex flex-column justify-content-center py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4">
            <h1 className="text-center fw-bold mb-4">
              Trash-n-Dash
            </h1>
            
            <div className="card shadow-sm">
              <div className="card-body p-4">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <div className="mb-4">
                  To continue, you need a collection kit. Ask around or <a href="https://discord.gg/mM7keSJMJJ">check our Discord</a> to get one.
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label 
                      htmlFor="username" 
                      className="form-label"
                    >
                      Wristband ID
                    </label>
                    <input
                      id="username"
                      name="username"
                      type="text"
                      required
                      value={username}
                      placeholder="Two letters"
                      onChange={(e) => setUsername(e.target.value.toLowerCase())}
                      className="form-control"
                    />
                  </div>

                  <div className="mb-4">
                    <label 
                      htmlFor="password" 
                      className="form-label"
                    >
                      Wristband PIN
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      value={password}
                      placeholder="Three numbers"
                      onChange={(e) => setPassword(e.target.value.toLowerCase())}
                      className="form-control"
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-primary w-100"
                  >
                    {isLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div 
                          className="spinner-border spinner-border-sm me-2" 
                          role="status"
                          aria-hidden="true"
                        />
                        Sliding down the chute...
                      </div>
                    ) : (
                      "Sign in"
                    )}
                  </button>
                </form>

                <div className="text-center mt-4">
                  Or <a href="/">view updates</a> as guest<br/><br/>
                  <span className="text-muted small">
                    "Trash is cash." --Day Ralio
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login