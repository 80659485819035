import { Outlet, NavLink, useNavigate } from "react-router-dom"
import { useState } from "react"
import { PlusIcon, HomeIcon, ChatBubbleBottomCenterTextIcon, DocumentTextIcon, } from "@heroicons/react/24/outline"
import { User, postLogout} from "../api/client"
import { ColorSquare } from "./Badges"

interface LayoutProps {
  user: User;
  onLogoutSuccess: (user: User | null) => void;
}

function Layout({ user, onLogoutSuccess }: LayoutProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [error, setError] = useState<string>("");
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      const response = await postLogout()
      if (response.status !== 200) {
        throw new Error("Logout failed")
      }
      onLogoutSuccess(null)
      navigate("/login")
    } catch (err) {
      setError("Logout failed")
      console.error("Logout failed", err)
    }
  }

  const navigation = [
    { name: "Backpack", href: "/", icon: HomeIcon },
    { name: "Rumor Mill", href: "/rumors", icon: ChatBubbleBottomCenterTextIcon },
    ...(user.remaining_lives > 0 ? [{ name: "Earn", href: "/earn", icon: PlusIcon }] : []),
    { name: "Orientation", href: "/rules", icon: DocumentTextIcon},
  ]

  return (
    <div className="min-vh-100 d-flex flex-column">
      <nav className="navbar navbar-expand-md navbar-dark bg-primary">
        <div className="container">
          <span className="navbar-brand">Trash-n-Dash</span>
          {/* Mobile toggle button */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`}>
            {/* Navigation Links */}
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              {navigation.map((item) => {
                const Icon = item.icon
                return (
                  <li className="nav-item" key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        `nav-link ${isActive ? 'active' : ''} d-flex align-items-center`
                      }
                    >
                      <Icon style={{ width: '20px', height: '20px' }} className="me-2" />
                      {item.name}
                    </NavLink>
                  </li>
                )
              })}
            </ul>

            {/* User Menu */}
            <div className="d-flex align-items-center">
				      <div className="d-flex align-items-center gap-2 text-white me-3">
                {user.name || "Visitor " + user.id} <ColorSquare color={user.team.color_code} />
              </div>
              <button
                onClick={handleLogout}
                className="btn btn-outline-light btn-sm"
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main content */}
      <main className="container py-4 flex-grow-1">
        <div className={"alert col-12 col-md-10 col-lg-8 mb-0 container " + (user.remaining_lives === 0 ? "alert-danger" : "alert-info")}>
          <div className="row justify-content-center text-center">
          {user.remaining_lives > 0 && (
            <>You can get robbed {user.remaining_lives} more {user.remaining_lives > 1 ? "times " : "time "}
              before losing.</>)}
          {user.remaining_lives === 0 && (
            <>You have been eliminated due to being robbed too many times 😭</>)}
          </div>
          {/* Error Alert */}
          {error && (
            <div className="alert alert-danger" role="alert">
              <div className="d-flex">
                <div className="ms-3">
                  {error}
                </div>
              </div>
            </div>)}
          </div>
        <Outlet />
      </main>

      {/* Footer */}
      <footer className="bg-white border-top py-3 mt-auto">
        <div className="container">
          <p className="text-center text-muted small mb-0">
            © 2024 Trash-n-Dash. All rights recycled.
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Layout