import React from 'react'
import { ClipboardIcon } from '@heroicons/react/24/outline'
import { BaseEvent, UserDisplay, EventFooter, TrashReward, TrashRewardDisplay } from './EventComponents'
import { EncounteredUser } from '../api/client'

export interface SurveyEvent extends BaseEvent {
  event_type: 'survey'
  surveyor: EncounteredUser
  answers: Record<string, string>
  reward: TrashReward
}
const SurveyStory: React.FC<{ event: SurveyEvent }> = ({ event }) => (
    <div className="border rounded p-3">
      {/* Header with icon and action text */}
      <div className="d-flex gap-2 align-items-center mb-2">
        <ClipboardIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />
        <span>Survey Completed</span>
      </div>
  
      {/* User info and rewards in their own row */}
      <div className="mb-3">
        <UserDisplay user={event.user} reward={event.reward} />
      </div>
      
      {/* Survey responses */}
      <div className="mb-2">
        {event.prompts.map((prompt, index) => (
          <div key={index} className="mb-1 text-muted small">
            <strong>{prompt.text}</strong> {prompt.answer}
          </div>
        ))}
      </div>
      
      <EventFooter event={event} />
    </div>
  )

export default SurveyStory