// frontend/src/api/client.ts
import axios, { AxiosResponse } from "axios"
import { TeamInfo } from "../components/Responses"

const API_BASE = import.meta.env.VITE_API_BASE

export const api = axios.create({
  baseURL: API_BASE,
  withCredentials: true,
})

export interface TestResponse {
  user_agent: string;
}

export const getTest = async (): Promise<TestResponse> => {
  const response = await api.get<TestResponse>("/api/get_test")
  return response.data
}


export interface User {
  id: number
  public_key: string
  remaining_lives: number
  name: string
  emoji: string
  phone: string
  team: TeamInfo
  score: number
}

export interface EncounteredUser {
  id: number
  public_key: string
  name: string
  emoji: string
  phone: string
  team: TeamInfo
  responses: SurveyResponse[] | null
}

interface SurveyResponse {
  prompt_id: number
  answer: string
}

export interface GetUserResponse {
  user: User | null;
}

export const getUser = async (): Promise<User | null> => {
  console.log("making the call")
  const response = await api.get<AxiosResponse>("/api/get_user")
  console.log("getUser response", response)
  return JSON.parse(response.data.user)
}

export const postLogin = async (username: string, password: string): Promise<User | null> => {

  const params = new URLSearchParams();
  params.append('id', username);
  params.append('password', password);
  params.append('team_id', "1");

  const response = await api.post<GetUserResponse>("/api/login", params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });

  if (response.status !== 200) {
    return null;
  }
  return JSON.parse(response.data.user)
}

export const postLogout = async (): Promise<AxiosResponse> => {
  return await api.post<GetUserResponse>("/api/logout")
}

export const postSteal = async (
  victimPublicKey: string,
  location: string): Promise<AxiosResponse> => {
  const params = new URLSearchParams();
  params.append('victim_public_key', victimPublicKey);
  params.append('location', location);

  return await api.post("/api/steal", params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });
}

export const postPatrol = async (
  visitorPublicKeys: string[]): Promise<AxiosResponse> => {
  const params = new URLSearchParams();
  params.append('visitor_public_keys', visitorPublicKeys.join(','));

  return await api.post("/api/patrol", params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });
}


export const postSignup = async (
  name: string,
  emoji: string,
  phone: string): Promise<User | null> => {
  const params = new URLSearchParams();
  params.append('name', name);
  params.append('emoji', emoji);
  params.append('phone', phone);

  const response = await api.post("/api/signup", params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });

  if (response.status !== 200) {
    return null;
  }
  return JSON.parse(response.data.user)
}

export const postSurveyResponses = async (
  answers: Record<number, string>): Promise<AxiosResponse> => {
  const formData = new FormData();

  for (const [question_id, answer] of Object.entries(answers)) {
    formData.append(question_id, answer);
  }

  return await api.post("/api/post_survey_responses", formData);
}

export const postGemDiscovery = async (code: string) => {
  console.log("code was", code)
  const params = new URLSearchParams();
  params.append('code', code);

  return await api.post("/api/gem_discovery", params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });
}

export const postTossing = async (params: {
  opponent_key: string
  player_score: number
  opponent_score: number
}) => {
  const formData = new URLSearchParams()
  formData.append('opponent_key', params.opponent_key)
  formData.append('player_score', params.player_score.toString())
  formData.append('opponent_score', params.opponent_score.toString())

  return await api.post("/api/tossing", formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}

export const getGlobalStats = async (godViewKey: string | null): Promise<any> => {
  const params = new URLSearchParams();
  if (godViewKey) {
    params.append('god_view_key', godViewKey);
  }
  const response = await api.get<AxiosResponse>("/api/global_stats", {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: params
  });

  const stats = response.data
  return stats
}

export const getPersonalStats = async (): Promise<any> => {
  const response = await api.get<AxiosResponse>("/api/personal_stats")
  const stats = response.data
  return stats
}

export const getActionData = async (): Promise<any> => {
  const response = await api.get<AxiosResponse>("/api/action_data")
  const data = response.data
  console.log("action data", data)
  return data
}