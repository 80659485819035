import { 
  MagnifyingGlassIcon, 
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline"
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { postPatrol } from "../api/client"
import { AxiosError } from "axios"
import { InstructionsCard } from './InstructionsCard'

interface VisitorInput {
  id: number
  publicKey: string
}

const patrolInstructions = [
  "Report to Room 1712",
  "Find one or more other visitors to form a trash sweep group",
  "This is an N-1-legged trash sweep!",
  "Each of your legs will be tied to another visitor's for safety reasons",
  "Follow the route as instructed by Trash-n-Dash staff"
]

const ReportPatrol: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const [showInstructions, setShowInstructions] = useState(true)
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [visitors, setVisitors] = useState<VisitorInput[]>([{ id: 1, publicKey: "" }])
  
  const navigate = useNavigate()

  if (showInstructions) {
    return (
      <InstructionsCard
        title="Trash Sweep"
        instructions={patrolInstructions}
        onBack={onBack}
        onContinue={() => setShowInstructions(false)}
      />
    )
  }
  
    const addVisitor = () => {
      if (visitors.length < 50) {
        setVisitors([...visitors, { id: visitors.length + 1, publicKey: "" }])
      }
    }
  
    const removeVisitor = (id: number) => {
      if (visitors.length > 1) {
        setVisitors(visitors.filter(visitor => visitor.id !== id))
      }
    }
  
    const updateVisitor = (id: number, value: string) => {
      setVisitors(visitors.map(visitor => 
        visitor.id === id ? { ...visitor, publicKey: value } : visitor
      ))
    }
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      setError("")
      setIsLoading(true)
  
      try {
        const visitorKeys = visitors.map(v => v.publicKey).filter(key => key.trim() !== "")
        if (visitorKeys.length === 0) {
          throw new Error("At least one visitor ID is required.")
        }
        await postPatrol(visitorKeys)
        navigate("/")
      } catch (err: any) {
        const message = err instanceof AxiosError ? err.response?.data?.detail : (err as Error).message
        console.error("Patrol submission failed:", message)
        setError(message || "Patrol submission failed :(")
      } finally {
        setIsLoading(false)
      }
    }
  
    return (
      <div className="card">
        <div className="card-body">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="h5 mb-0">Claim Group Trash Sweep</h3>
            <button className="btn btn-sm btn-outline-secondary" onClick={onBack}>
              <i className="bi bi-arrow-left"></i> Back
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            {visitors.map((visitor) => (
              <div key={visitor.id} className="mb-3">
                <div className="input-group">
                  <label className="input-group-text">Visitor ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={visitor.publicKey}
                    pattern="^[a-z]{2}$"
                    maxLength="2"
                    onChange={(e) => updateVisitor(visitor.id, e.target.value)}
                    placeholder="e.g. xy"
                    required
                  />
                  {visitors.length > 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => removeVisitor(visitor.id)}
                    >
                      <TrashIcon style={{ width: '1.25rem', height: '1.25rem' }} />
                    </button>
                  )}
                </div>
              </div>
            ))}
            
            {visitors.length < 50 && (
              <button
                type="button"
                className="btn btn-outline-secondary mb-3"
                onClick={addVisitor}
              >
                <PlusIcon style={{ width: '1.25rem', height: '1.25rem' }} className="me-2" />
                Add Another Visitor
              </button>
            )}
  
            <div>
              <button type="submit" className="btn btn-success">
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    />
                    Submitting...
                  </div>
                ) : (
                  <>
                    <MagnifyingGlassIcon style={{ width: '1.25rem', height: '1.25rem' }} className="me-2" />
                    Submit
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
  
  export default ReportPatrol