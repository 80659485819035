import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { User, getUser } from "./api/client";
import Backpack from "./pages/Backpack";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import Rumors from "./pages/Rumors";
import GodRumors from "./pages/GodRumors";
import Rules from "./pages/Rules";
import Earn from "./pages/Earn";
import Layout from "./components/Layout";
import Signup from "./pages/Signup";
import iconPath from "./assets/trashndash_icon_no_text.png"

function App() {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  console.log("first step")

  useEffect(() => {
    const link = document.querySelector<HTMLLinkElement>("link[rel*='icon']") || document.createElement('link')
    //link.type = 'image/x-icon'
    link.rel = "shortcut icon"
    link.href = iconPath
    document.getElementsByTagName('head')[0].appendChild(link)

    // Check session with FastAPI backend
    const checkSession = async () => {
      try {
        console.log("getting the user?")
        const user: User = (await getUser())!
        console.log("from app: user", user)
        {console.log("user type:", typeof user)}
        setUser(user)
      } finally {
        setLoading(false)
      }
    }
    checkSession()
  }, [])

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        />
        Alchemizing...
      </div>)
  }

  console.log("user", user)

  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/login" 
          element={user ? <Navigate to="/" /> : <Login onLoginSuccess={setUser}/>} 
        />
        <Route 
          path="/" 
          element={user ? <Navigate to="/backpack"/> : <LandingPage/>}
        />

    {user ? (
      !user.name ? <Route path="*" element={<Signup user={user} onSubmitSuccess={setUser} />}/> : (
        <Route element={<Layout user={user} onLogoutSuccess={setUser} />}>
          <Route path="/backpack" element={<Backpack user={user}/>} />
          <Route path="/rumors" element={<Rumors user={user}/>} />
          <Route path="/god_rumors" element={<GodRumors user={user}/>} />
          <Route path="/earn" element={<Earn viewer={user} />} />
          <Route path="/rules" element={<Rules user={user}/>} />
        </Route>
    )) : (
      /* Catch-all redirect to login if no user */
      <Route path="*" element={<Navigate to="/" />} />
    )}
     </Routes>
    </BrowserRouter>
  )
}

export default App