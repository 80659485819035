import React, { useState } from 'react';
import { User } from "./api/client";

interface RulesToggleProps {
  isSimple: boolean
  onToggle: (isSimple: boolean) => void
}

const RulesToggle: React.FC<RulesToggleProps> = ({ isSimple, onToggle }) => (
  <div className="d-flex justify-content-center mb-4">
    <div className="btn-group" role="group" aria-label="Rules detail level">
      <button 
        type="button" 
        className={`btn ${isSimple ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => onToggle(true)}
      >
        Detailed
      </button>
      <button 
        type="button" 
        className={`btn ${!isSimple ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => onToggle(false)}
      >
      I don't read good
      </button>
    </div>
  </div>
)

interface RulesSectionProps {
  isSimple: boolean,
  user: User | null
}

const RulesSection: React.FC<RulesSectionProps> = ({ isSimple, user }) => {
  const sharedSections = (<>
    {/* Mission Section */}
    <div className="mb-4">
    <h2 className="h4 text-secondary mb-3">Your Mission</h2>
    <div className="alert alert-info">
        <p>Factory visitors like you are being recruited to help collect the magical trash needed
        for the island's survival.</p>
        <p className="mb-0">At the end of the weekend -- see the countdown timer -- the recycling facility will accept
        every individual's trash. There will be actual (physical) trash rewards for the top 2 scorers and the top scorer on the highest-scoring faction.
        You will like this trash and share it with your friends.
        </p>
    </div>
    </div>

    {/* Gameplay Rules */}
    <div className="mb-4">
    <h2 className="h4 text-secondary mb-3">How to Play</h2>
    <div className="card border-primary">
        <div className="card-header bg-primary text-white">
        Basic Rules
        </div>
        <div className="card-body">
        <ul className="list-group list-group-flush">
            <li className="list-group-item">You wear your wristband at all times</li>
            <li className="list-group-item">You "carry a backpack" containing your collected trash</li>
            <li className="list-group-item">You earn trash by:
            </li>
            <li className="list-group-item">Existing. Just log in to start and keep exploring the factory.</li>
            <li className="list-group-item">Sweepin' trash with friends -- at Room 1727</li>
            <li className="list-group-item">Tossin' trash with a friend -- at Room 1727</li>
            <li className="list-group-item">Diggin' for trash -- anywhere in the factory</li>
            <li className="list-group-item">Stealing trash from other visitors wearing a Trash-n-Dash wristband -- anywhere</li>
            <li className="list-group-item"><a href="/earn">Start earning trash now!</a></li>
            <li className="list-group-item">After being robbed 5 times, you leave the program</li>
            <li className="list-group-item">You cannot steal from your own faction</li>
            <li className="list-group-item">DO NOT steal from visitors not wearing the Trash-n-Dash recycling program bracelet</li>
        </ul>
        </div>
    </div>
    </div>

    {/* Stealing Mechanics */}
    <div className="mb-4">
    <h2 className="h4 text-secondary mb-3">Stealing Mechanics</h2>
    <div className="card border-danger">
        <div className="card-header bg-danger text-white">
        How to Steal
        </div>
        <div className="card-body">
        <ol className="list-styled">
            <li className="mb-2">Use your clothespin weapon to clip onto target's clothes/accessories</li>
            <li className="mb-2">Count 5 seconds ("1 Mississippi, 2 Mississippi, ...")</li>
            <li className="mb-2">Done before they notice? Robbery successful!</li>
            <li className="mb-2">Let them know and record their info (number and ID, e.g. 12 and "xy")</li>
            <li className="mb-2">You can't steal from someone sleeping, lying in bed, or driving.</li>
            <li className="mb-2"><a href="/steal">Record the steal here</a> within 3 minutes, <b>or you forfeit it!</b></li>
					<li>The two of you now can't rob each other for 30 minutes</li>
        </ol>
        </div>
    </div>
    </div>

    {/* Important Notes */}
    <div className="mb-4">
			<h2 className="h4 text-secondary mb-3">Important Notes</h2>
			<div className="alert alert-warning">
					<ul className="mb-0">
					<li>It doesn't matter if anyone else besides the victim sees the steal</li>
					<li>If you notice a steal attempt by a rival, intervene to prevent it</li>
					<li>You can <a href="/">dispute a steal against yourself</a></li>
				<li>Lastly, remember your ABDs to help spread the good word of this opportunity:</li>
				<li><b>Always be dramatic.</b> See a theft attempt? Shout: "THIEF". Get robbed? "OH MY GOD YOU BASTARD".
				Friend standing next to you? Tug on their shirt to freak them out.</li>
				</ul>
			</div>
    </div>

    <div className="mb-4">
    <h2 className="h4 text-secondary mb-3">Got questions?</h2>
    <div className="card border-primary">
        <div className="card-header bg-primary text-white">
        FAQ
        </div>
        <div className="card-body">
        <ul className="list-group list-group-flush">
            <li className="list-group-item">Q: What's my ID and PIN? A: On your wristband.</li>
            <li className="list-group-item">Q: How do I get a wristband and clothespin?
              A: Room 1727 or high-trafficked areas (e.g. by elevators), ping us in Discord or at 415-867-7120.</li>
            <li className="list-group-item">Q: Can I collect trash without wearing the wristband/stealing? A: Not this year, sorry.</li>
            <li className="list-group-item">Q: Where's my trash? Do I need to take something from others? A: No, it's all virtual, except the prizes.</li>
        </ul>
        </div>
    </div>
    </div>


  </>)

  const detailedRules = (
        <>
              {/* Header Section */}
              <div className="text-center mb-4">
                <div className="alert alert-success mb-0">
                  <p className="mb-0">Congratulations{user ? ", " + user?.name : ""}! You've been selected as a part-time trash collector on Magical Trash Island ✨
                  </p>
                </div>
								<br/>First, <a href="https://discord.gg/mM7keSJMJJ">join our Discord chat </a>
                in case of we ever need to talk.
								<br/>
								Then let's get you up to speed:
              </div>
    
              {/* Background Section */}
              <div className="mb-4">
                <h2 className="h4 text-secondary mb-3">Current Situation</h2>
                <div className="card bg-light">
                  <div className="card-body">
                    <p>These are tough times on Magical Trash Island. Mx. Garbaggio's recognized creativity 
                    is of the essence in order to prevent the island from sinking into the sea. All
                    shiny trash must be found and turned in for alchemizing.</p>
                    <p>For that reason, this weekend, Mx. Garbaggio has subcontracted the collection of trash around the island
                    to a few local non-profits. Alas, as often happens in dire conditions, there's been
                    unexpected consequences.</p>
                    <p>Factory workers have started stealing (virtual) trash from each other and split into very mean rival factions. 
                    But one's tragedy is another's opportunity. In the word of famed Magical Trash Island investment banker
										Day Ralio, "Trash is cash!"</p>
                  </div>
                </div>
              </div>
					{sharedSections}
        </>)

  return (
    <div className="card" style={{ maxWidth: '600px', margin: '0 auto' }}>
      {/*
      <div className="card-header">
        <h5 className="card-title mb-0">Game Rules</h5>
      </div>
      */}
      <div className="card-body">
        <div className="text-body" style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
          {!isSimple ? sharedSections : detailedRules}
        </div>
      </div>
    </div>
  )
}

interface RulesPageProps {
  user: User | null
}

const Rules: React.FC<RulesPageProps> = ({user}) => {
  const [isSimple, setIsSimple] = useState(true)

  return (
    <div className="container-fluid py-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          <RulesToggle isSimple={isSimple} onToggle={setIsSimple} />
          <RulesSection isSimple={isSimple} user={user} />
        </div>
      </div>
    </div>
  )
}

export default Rules