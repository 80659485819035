import { useState, useEffect } from "react"
import { User, getPersonalStats } from "../api/client"
import { BackpackPage, Steal } from "../components/Stats.tsx"
import { TeamInfos } from "../components/Responses.tsx"

interface PersonalStats {
	backpack: Record<number, number>
	steals: Steal[]
  team_infos: TeamInfos
  end_time: number
}

function Backpack({ user }: { user: User }) {
  const [stats, setStats] = useState<PersonalStats | null>(null)
  const [error, setError] = useState("")

  useEffect(() => {
    const getStats = async () => {
      try {
        const stats = await getPersonalStats()
        console.log("stats", stats)
        setStats(stats)
      } catch (err) {
        setError(err instanceof Error ? err.message : "Getting backpack state failed :(")
          console.error(err)
        }
    }
    getStats()
  }, [])

  console.log("bp stats", stats)

  return (stats ? 
    (<BackpackPage 
      viewerID={user.id}
      backpack={stats.backpack}
      events={stats.events}
      teams={stats.team_infos}
      endTime={stats.end_time}
      rank={stats.rank}
  />) : (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        />
        Alchemizing...
      </div>))
}

export default Backpack