import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { EncounteredUser } from "../api/client"
import { BaseEvent, EventFooter, TrashReward, UserDisplay } from "./EventComponents"

interface DigginEvent extends BaseEvent {
  event_type: 'diggin'
  digger: EncounteredUser
  reward: TrashReward
}

export const GemDiscoveryStory: React.FC<{ event: DigginEvent }> = ({ event }) => {
 
  return (
    <div className="border rounded p-3">
      {/* Header with icon and action text */}
      <div className="d-flex gap-2 align-items-center mb-2">
        <MagnifyingGlassIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />
        <span>Shiny Trash Dug Up</span>
      </div>
  
      {/* User info and rewards in their own row */}
      <div className="mb-3">
        <UserDisplay user={event.user} reward={event.reward} />
      </div>
      
      <EventFooter event={event} />
    </div>

  )
}