import React, { useState } from 'react'
import Rules from './Rules' 
import Rumors from './Rumors'
import landingPageCover from '../assets/landing_page_cover.png'
import icon from '../assets/trashndash_icon_no_text.png'


const LandingPage: React.FC = ({ }) => {
  return (
    <div>
      {/* Hero Section */}
      <div className="position-relative overflow-hidden p-3 p-md-5 bg-light mb-4">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6 order-md-2 mb-4 mb-md-0">
              {/* Hero Image */}
              <div className="position-relative">
                <img 
                  src={landingPageCover}
                  alt="People playing Trash-n-Dash"
                  className="img-fluid rounded shadow"
                />
                <div className="position-absolute top-0 start-0 mt-3 ms-3">
                  <img 
                    src={icon}
                    alt="Trash-n-Dash icon"
                    width="60"
                    height="60"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-1">
              <div className="p-lg-5">
                <h1 className="display-4 fw-bold mb-3">Welcome to Trash-n-Dash!</h1>
                <p className="lead mb-4">
                  <b>Social hour, snacks, missions Sat 9-10pm Room 1727</b><br/><br/>
                  Join the most exciting recycling program on Magical Trash Island. 
                  Collect trash, dodge rivals, and help save the island!
                </p>
                <div className="d-flex gap-3">
                <a href="/login" className="btn btn-primary btn-lg px-4">
                    Join a Faction
                </a>
                <a href="#rules" className="btn btn-outline-secondary btn-lg px-4">
                    Learn More
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Decorative Background Elements */}
        <div className="position-absolute top-0 end-0 mt-n4 me-n4 d-none d-lg-block">
          <div 
            className="bg-primary opacity-10" 
            style={{
              width: '200px',
              height: '200px',
              transform: 'rotate(45deg)'
            }}
          />
        </div>
      </div>

      <Rumors user={null} />

      {/* Rules Section */}
      <div id="rules" className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center mb-5">How to Play</h2>
              <Rules user={null} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage