import React from 'react'
import { ColorSquare } from './Badges'
import { TeamInfos } from './Responses'

export interface PublicUserView {
  id: number
  name: string
  emoji: string | null
  team_id: number
}

interface RosterProps {
  visitors: PublicUserView[]
  teams: TeamInfos
}

const Roster: React.FC<RosterProps> = ({ visitors, teams }) => {
  // Group members by team
  const membersByTeam = Object.values(visitors).reduce((acc, member) => {
    if (!acc[member.team_id]) {
      acc[member.team_id] = []
    }
    acc[member.team_id].push(member)
    return acc
  }, {} as Record<number, PublicUserView[]>)

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Rosters</h5>
      </div>
      <div className="card-body">
        <div className="row g-2">
          {Object.entries(membersByTeam).map(([teamId, teamMembers]) => (
            <React.Fragment key={teamId}>
              {teamMembers.map((member) => (
                <div key={member.id} className="col-md-3 col-sm-4 col-6">
                  <div className="border rounded p-2 d-flex align-items-center gap-2">
                    <ColorSquare color={member.team.color_code} />
                    {member.emoji && <span>{member.emoji}</span>}
                    <span className="text-truncate">
                      {member.name || "Super Sneaky"}
                    </span>
                  </div>
                </div>
              ))}
              <div className="w-100"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
  /*
  (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Team Members</h5>
      </div>
      <div className="card-body">
        {Object.entries(membersByTeam).map(([teamId, teamMembers]) => (
          <div key={teamId} className="mb-4 last:mb-0">
            <div className="d-flex align-items-center mb-2">
              <ColorSquare color={teams[teamId]?.color_code} />
              <h6 className="mb-0 ms-2 fw-bold">{teams[teamId]?.name}</h6>
            </div>
            
            <div className="row g-2">
              {teamMembers.map((member, index) => (
                <div key={index} className="col-md-3 col-sm-4 col-6">
                  <div className="border rounded p-2">
                    {member.name ?? "Super Sneaky"} {member.emoji}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
    */
}

export { Roster }