import React from 'react'
import { 
  ClockIcon, 
  UserGroupIcon, 
  MapPinIcon, 
  SparklesIcon, 
  TrophyIcon
} from '@heroicons/react/24/outline'
import { TeamInfos } from './Responses'
import { ColorCircle } from './Badges'
import SurveyStory, { SurveyEvent } from './SurveyStory'
import { BaseEvent, EventFooter, MissionParticipantDisplay, TossinParticipantDisplay, TrashReward } from './EventComponents'
import { GemDiscoveryStory } from './GemDiscoveryStory'

interface EncounteredUser {
  id: number
  name: string
  public_key: string
  team: {
    id: number
    name: string
    color_code: string
  }
}

// Event-specific interfaces
interface StealEvent extends BaseEvent {
  event_type: 'steal'
  robber: EncounteredUser
  victim: EncounteredUser
  contents: TrashReward
}

interface PatrolEvent extends BaseEvent {
  event_type: 'patrol'
  joiners: EncounteredUser[]
  photos?: string[]
  rewards: Record<number, TrashReward> // key is user.id
}

interface TossinEvent extends BaseEvent {
  event_type: 'tossin'
  player1: EncounteredUser
  player2: EncounteredUser
  winner_id: number
  rewards: Record<number, TrashReward> // key is user.id
}


interface ActivationEvent extends BaseEvent {
  event_type: 'activation'
  visitor: EncounteredUser
  reward: TrashReward
}

export type Event = StealEvent | PatrolEvent | SurveyEvent | TossinEvent | DigginEvent | ActivationEvent

interface StealStoryProps { 
  event: StealEvent
  teams: TeamInfos
  viewerID: number | null
  anonymized: boolean | null
}

const StealStory: React.FC<StealStoryProps> = ({ event, viewerID, teams, anonymized = false }) => {
  const TeamDisplay: React.FC<{ user: EncounteredUser }> = ({ user }) => (
    <div
      className="p-2 rounded d-flex gap-2 align-items-center" 
      style={{ backgroundColor: user.team.color_code }}
    >
      {anonymized ? user.team.name : (
        <>
          <span>{user.name}</span>
          <span className="text-muted small">{user.public_key}</span>
        </>
      )}
    </div>
  );

	const contentsSum = Object.values(event.reward).reduce((sum, count) => sum + count, 0);
  return (
    <div className="border rounded p-3">
      {!anonymized && viewerID && (
        <div className="me-2 mb-2">
          {(event.robber.id === viewerID ? "Scored" : "Lost") + " " + contentsSum + " trash"}
        </div>
      )}

      <div className="d-flex gap-2 align-items-center mb-2">
        <TeamDisplay user={event.robber} />
        <span>→</span>
        <TeamDisplay user={event.victim} />
      </div>
      <div className="d-flex gap-2 flex-wrap mb-2">
        {Object.entries(event.reward).map(([teamId, count]) => count > 0 && (
          <div key={teamId} className="d-flex align-items-center gap-1">
					  <ColorCircle color={teams[teamId]?.color_code} />
            <span>{count}</span>
          </div>
        ))}
      </div>
      <EventFooter event={event} />
    </div>
  );
};
        
const PatrolStory: React.FC<{ event: PatrolEvent }> = ({ event }) => (
  <div className="border rounded p-3">
    <div className="d-flex gap-2 align-items-center mb-2">
      <UserGroupIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />
      <span>{event.joiners.length} visitors completed a trash sweep</span>
    </div>
    <div className="d-flex flex-wrap gap-2 mb-2">
      {event.joiners.map(participant => (
          <MissionParticipantDisplay key={participant.user.id} participant={participant} />
      ))}
    </div>
    {event.photos && event.photos.length > 0 && (
      <div className="d-flex gap-2 mb-2">
        {event.photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Patrol photo ${index + 1}`}
            className="rounded"
            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
          />
        ))}
      </div>
    )}
    <EventFooter event={event} />
  </div>
)


const TossinStory = ({ event }) => {
  const participants = event.joiners
  const winner = participants.find(p => p.user.id === event.winner.id)
  const loser = participants.find(p => p.user.id !== event.winner.id)
  return (
    <div className="border rounded p-3">
      <div className="flex gap-2 items-center mb-2">
        <TrophyIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2"/>
        <span>{event.joiners.length} visitors tossed trash</span>
      </div>
      <div className="flex flex-wrap gap-2 mb-2">
          <TossinParticipantDisplay
            participant={winner}
            winner={true}
            winnerMultiplyer={event.winner_multiplier}/>
          <TossinParticipantDisplay
            participant={loser}
            winner={false}
            winnerMultiplyer={event.winner_multiplier}/>
      </div>
      <EventFooter event={event} />
    </div>
  )
}

const ActivationStory: React.FC<{ event: ActivationEvent }> = ({ event }) => (
  <div className="border rounded p-3">
    <div className="d-flex gap-2 align-items-center mb-2">
      <MissionParticipantDisplay user={event.visitor} />
      <span>joined the cleanup</span>
      <TrashRewardDisplay rewards={event.reward} />
    </div>
    <EventFooter event={event} />
  </div>
)

// Main EventFeed component
interface EventFeedProps {
  events: Event[]
  teams: TeamInfos
  viewerID: number | null
  anonymized: boolean
}

const EventFeed: React.FC<EventFeedProps> = ({ viewerID, events, teams, anonymized}) => {
  const renderEvent = (event: Event) => {
    console.log("rendering event of type", event.event_type)
    switch (event.event_type) {
      case 'steal':
        return <StealStory viewerID={viewerID} event={event} teams={teams} anonymized={anonymized} />
      case 'patrol':
        return <PatrolStory event={event} />
      case 'survey':
        return <SurveyStory event={event} />
      case 'tossin':
        return <TossinStory event={event} />
      case 'diggin':
        return <GemDiscoveryStory event={event} />
      case 'activation':
        return <ActivationStory event={event} />
    }
  }

  console.log("events", events)
    return (
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Activity Feed</h5>
        </div>
        <div className="card-body">
          <div className="d-flex flex-wrap gap-3">
            {events
              .sort((a, b) => b.occurred_at - a.occurred_at)
              .map(event => (
                <div 
                  key={`${event.event_type}-${event.id}`}
                  style={{ flex: '1 1 300px', maxWidth: '100%', minWidth: '300px' }}
                >
                  {renderEvent(event)}
                </div>
              ))}
          </div>
        </div>
      </div>)

}

export default EventFeed