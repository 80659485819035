import { 
  TrashIcon,
  ScissorsIcon, 
  UserIcon, 
  UsersIcon, 
  MagnifyingGlassIcon, 
  ExclamationTriangleIcon, 
  FlagIcon,
  ArrowPathIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline"
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { User, getActionData, postSteal } from "../api/client"
import { Survey, SurveyData, Buddies } from "../components/Survey"
import ReportPatrol from "../components/ReportPatrol"
import { AxiosError } from "axios"
import LocateForm from "../components/LocateForm"
import ReportTossing from "../components/ReportTossing"

type ActionType = 'steal' | 'patrol' | 'dig' | 'toss' | 'survey' | 'buddies'

interface Action {
  id: ActionType
  title: string
  description: string
  icon: string // Bootstrap icon class
  color: string // Bootstrap color class
}

interface ActionData {
  survey: SurveyData
}

const initialActions: Action[] = [
  {
    id: 'steal',
    title: 'Steal Trash',
    description: 'Report a successful theft from another visitor',
    icon: 'scissors',
    color: 'danger',
  },
  {
    id: 'survey',
    title: 'Who Are You?',
    description: '4 short questions to earn trash and make friends. Answers are public.',
    icon: 'user',
    color: 'info', 
  },
  {
    id: 'buddies',
    title: 'Who Are Your Buddies?',
    description: 'Pre-Missed connections that give you BONUS trash',
    icon: 'users',
    color: 'info'
  },
  {
    id: 'patrol',
    title: 'Trash Sweep',
    description: 'Collect trash as a tight-knit group',
    icon: 'recycle',
    color: 'success'
  },
  {
    id: 'dig',
    title: "Diggin' Trash",
    description: 'Report shiny trash found in the factory',
    icon: 'search',
    color: 'warning'
  },
  {
    id: 'toss',
    title: "Tossin' Trash",
    description: "Report a round of trash tossin' between you and another visitor",
    icon: 'ball',
    color: 'success'
  },
  /*
  {
    id: 'report',
    title: 'Report Issue',
    description: 'Report a rules violation or technical problem',
    icon: 'bi-flag',
    color: 'info'
  }
  */
]

const StealForm: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [victimPublicKey, setVictimPublicKey] = useState<string>("")
  const [location, setLocation] = useState<string>("")

  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    try {
      if (victimPublicKey) {
        const response = await postSteal(victimPublicKey, location)
      } else {
        throw new Error("Visitor ID is required.")
      }
      navigate("/")
    } catch (err: any) {
      const message = err instanceof AxiosError ? err.response?.data?.detail : (err as Error).message
      console.error("Steal report failed:", message)
      setError(message || "Steal report failed :(")
    } finally {
      setIsLoading(false)
    }
  }


  return (
    <div className="card">
      <div className="card-body">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="h5 mb-0">Claim Steal</h3>
          <button className="btn btn-sm btn-outline-secondary" onClick={onBack}>
            <i className="bi bi-arrow-left"></i> Back
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Victim's ID (e.g. "xy")</label>
            <input
              id="victimPublicKey"
              name="victimPublicKey"
              type="text"
              className="form-control"
              value={victimPublicKey}
              pattern="^[a-z]{2}$"
              maxLength="2"
              onChange={(e) => setVictimPublicKey(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Location</label>
            <input
              id="location"
              name="location"
              type="text"
              className="form-control"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Room 1102, Taqueria Cancun, etc."
              required
            />
          </div>
          <button type="submit" className="btn btn-danger">
            {isLoading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                />
                Submitting...
              </div>) : (<>
                <ScissorsIcon style={{ width: '1.25rem', height: '1.25rem' }} className="me-2" />
                Submit</>)}
            </button>
        </form>
      </div>
    </div>
  )
}


interface ActionPageProps {
  viewer: User | null
}

const Earn: React.FC<ActionPageProps> = ({ viewer }) => {
  const [selectedAction, setSelectedAction] = useState<ActionType | null>(null)
  const [actionData, setActionData] = useState<ActionData | null>(null)

  useEffect(() => {
    (async () => {
      try {
        const data = await getActionData()
        setActionData(data)
      } catch (err) {
        console.error('Failed to fetch action data:', err)
      }
    })()
  }, [])

  console.log("survey", actionData?.survey)

  const actions = initialActions.filter(action => 
      (action.id === 'survey' && (actionData && actionData.survey && !actionData.survey.has_user_taken_survey)
      || action.id === 'buddies' && (actionData && actionData.survey && actionData.survey.has_user_taken_survey)
      || action.id !== 'survey' && action.id !== 'buddies')
  )

  const renderActionContent = () => {
    switch (selectedAction) {
      case 'steal':
        return <StealForm onBack={() => setSelectedAction(null)} />
      case 'patrol':
        return <ReportPatrol onBack={() => setSelectedAction(null)} />
      case 'dig':
        return <LocateForm onBack={() => setSelectedAction(null)} />
      case 'toss':
        return <ReportTossing onBack={() => setSelectedAction(null)} userPublicKey={viewer?.public_key} />
      case 'report':
        return (
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="h5 mb-0">Coming Soon</h3>
                <button 
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => setSelectedAction(null)}
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </div>
              <p>This feature is not yet implemented.</p>
            </div>
          </div>
        )
      case 'survey':
        return (<Survey surveyData={actionData.survey} onBack={async () => {
            setSelectedAction(null)
            try {
              const data = await getActionData()
              setActionData(data)
            } catch (err) {
              console.error('Failed to fetch action data:', err)
            }

          }}/>)
      case 'buddies':
        return (<Buddies survey={actionData?.survey} onBack={() => setSelectedAction(null)} />)
      default:
          return (
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {actions.map(action => {
                const reward = actionData[action.id]?.reward
                //for (const actionKey in actionData) {
                  //console.log("actionKey", actionKey)
                //}
                console.log("actionData", reward)
                return (
                <div key={action.id} className="col">
                  <div 
                    className="card h-100 cursor-pointer"
                    onClick={() => setSelectedAction(action.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="card-body d-flex flex-column">
                      <div className={`d-flex align-items-center mb-2 text-${action.color}`}>
                        {action.icon === 'scissors' && <ScissorsIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        {action.icon === 'user' && <UserIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        {action.icon === 'users' && <UsersIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        {action.icon === 'search' && <MagnifyingGlassIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        {action.icon === 'recycle' && <ArrowPathIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        {action.icon === 'alert-triangle' && <ExclamationTriangleIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        {action.icon === 'ball' && <TrashIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        {action.icon === 'flag' && <FlagIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />}
                        <h5 className="card-title mb-0">{action.title}</h5>
                      </div>
                      <p className="card-text text-muted">{action.description}</p>
                      {reward && (
                        <div className="mt-auto pt-2 border-top">
                          <div className="d-flex align-items-center text-secondary">
                              <small>
                                <SparklesIcon style={{ width: '1rem', height: '1rem' }} className="me-1" />
                                {reward}
                              </small>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )})}
            </div>
            )}
    }
  return (
    <div className="container-fluid py-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          {!selectedAction && (
            <div className="text-center mb-4">
              <h1 className="h3 text-secondary">Trash Opportunities</h1>
              <p className="text-muted">Pick something</p>
            </div>
          )}
          {actionData && renderActionContent()}
          {!actionData && (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  )
}

export default Earn