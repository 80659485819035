import { useState, useEffect } from "react"
import { getGlobalStats } from "../api/client"
import { GlobalStatsPage } from "../components/Stats.tsx"

function Rumors() {
  const [stats, setStats] = useState<Stats | null>(null)
  const [error, setError] = useState("")

  useEffect(() => {
    const getStats = async () => {
      try {
        const stats = await getGlobalStats()
        console.log("stats/rumors was: ", stats)
        setStats(stats)
      } catch (err) {
        setError(err instanceof Error ? err.message : "Error loading rumors :(")
          console.error(err)
        }
    }
    getStats()
  }, [])

  return (stats ? 
    (<GlobalStatsPage 
    visitors={stats.visitors}
    teamTallies={stats.team_tallies}
    backpacks={stats.backpacks}
    steals={stats.steals}
    teams={stats.team_infos}
    events={stats.events}
    endTime={stats.end_time}
  />) :  (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        />
        Alchemizing...
      </div>))

}

export default Rumors